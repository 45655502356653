import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Subscribe from "../components/subscribe"

import { GatsbyImage } from "gatsby-plugin-image"

import * as BtcIcon from "../components/icons/BTC_1.svg"
import * as DaiIcon from "../components/icons/DAI_4943.svg"
import * as EtcIcon from "../components/icons/ETC_1321.svg"
import * as EthIcon from "../components/icons/ETH_1027.svg"
import * as UsdtIcon from "../components/icons/USDT_825.svg"
import * as UsdcIcon from "../components/icons/USDC_3408.svg"

import BackgroundImage from "../images/bg3.jpg"

import Viewport from "../components/Viewport"
import Header from "../components/header"

const BackgroundStyle = {
  backgroundImage: `url(${BackgroundImage})`,
  backgroundAttachment: `fixed`,
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      screen01: file(relativePath: { eq: "wallet01.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  console.log(data)

  return (
    <Layout
      header={<Header onTopClassName={"transparent"} offTopClassName={""} />}
    >
      <Seo
        title="Emerald Wallet"
        description="Desktop Cryptocurrency Wallet for Ethereum, Ethereum Classic, USD Coin (USDC), Tether (USDT), DAI, and others"
      />

      <section className="hero" style={BackgroundStyle}>
        <article>
          <h1>Empower to Adopt Cryptocurrency</h1>

          <div className="icon-group">
            <figure className="icon">
              <BtcIcon />
              <figcaption>Bitcoin</figcaption>
            </figure>

            <figure className="icon">
              <EthIcon />
              <figcaption>Ethereum</figcaption>
            </figure>

            <figure className="icon">
              <UsdcIcon />
              <figcaption>USDC / USD Coin</figcaption>
            </figure>

            <figure className="icon">
              <UsdtIcon />
              <figcaption>USDT / Tether</figcaption>
            </figure>

            <figure className="icon">
              <DaiIcon />
              <figcaption>DAI</figcaption>
            </figure>

            <figure className="icon">
              <EtcIcon />
              <figcaption>Ethereum Classic</figcaption>
            </figure>
          </div>
        </article>
      </section>

      <section className="home">
        <div className="wallet">
          <Viewport threshold={0} rootMargin="0px 0px -200px 0px">
            <figure className="">
              <GatsbyImage
                image={data.screen01.childImageSharp.gatsbyImageData}
                alt={"Emerald Wallet"}
              />
            </figure>
          </Viewport>
        </div>

        <div>
          <Viewport threshold={0} rootMargin="0px 0px -200px 0px">
            <h1>
              Download the latest release of Emerald Wallet for Windows, macOS
              and Linux.
            </h1>
          </Viewport>

          <Viewport
            threshold={0}
            rootMargin="0px 0px -200px 0px"
            className="center"
          >
            <Link to="download">
              <button>Download</button>
            </Link>
          </Viewport>
        </div>
      </section>

      <section className="subscribe">
        <Viewport
          threshold={0}
          rootMargin="0px 0px -200px 0px"
          className="center"
        >
          <Subscribe />
        </Viewport>
      </section>
    </Layout>
  )
}

export default IndexPage
