import React from "react"
import * as Sphere from "./icons/gdpr-sphere.svg"

const Component = () => {
  const [agree, setAgree] = React.useState(false)
  const [email, setEmail] = React.useState(false)

  function isEmail(value) {
    return (
      value.length > 1 &&
      value.indexOf("@") > 0 &&
      value.length > value.indexOf("@")
    )
  }

  return (
    <div className="subscription">
      <h4>Enter your email address to subscribe to announcements</h4>
      <div className="sib-form">
        <div id="sib-form-container" className="sib-form-container">
          <div
            id="sib-container"
            className="sib-container--large sib-container--vertical"
          >
            <form
              id="sib-form"
              method="POST"
              action="https://c2508b0a.sibforms.com/serve/MUIEAKBflio7ba0SXYB93MIMORkCILJD0o2jDp_kmPT2D3__BjuOIIc_TZauprlpF6vipQO9c3V0X8_DmziCNW5GqgpJdBTEayF6AbGn9xorfWKsy5AiVc8jP-FLxUJsFyUvKy6r8GfCOrcLOsDSRt9Jio-Zgsy3svnKKJkpGK_pVDSWKKyGCxVEWtPM6SiBQsOGxA2Br5LIv0YO"
            >
              <div className="item">
                <div className="sib-input sib-form-block">
                  <div className="form__entry entry_block">
                    <div className="form__label-row ">
                      <input
                        className="input email"
                        type="text"
                        name="EMAIL"
                        autoComplete="off"
                        onChange={e => setEmail(isEmail(e.target.value))}
                        placeholder="EMAIL"
                        data-required="true"
                        required
                      />
                      <button
                        className={""}
                        disabled={!agree || !email}
                        form="sib-form"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="sib-optin sib-form-block">
                  <div className="form__entry entry_mcq">
                    <div className="form__label-row ">
                      <div className="entry__choice">
                        <label>
                          <input
                            type="checkbox"
                            className="input_replaced"
                            value="1"
                            id="OPT_IN"
                            onChange={e => setAgree(e.target.checked)}
                            name="OPT_IN"
                          />
                          <span>
                            <p>
                              I agree to receive your newsletters and to terms
                              and conditions.
                            </p>
                          </span>
                        </label>
                      </div>
                    </div>
                    <label className="entry__error entry__error--primary"></label>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="sib-form__declaration">
                  <div className="declaration-block-icon">
                    <Sphere />
                  </div>
                  <p>
                    We use Sendinblue as our marketing platform. By Clicking
                    above to submit this form, you acknowledge that the
                    information you provided will be transferred to Sendinblue
                    for processing in accordance with their&nbsp;
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      className="clickable_link"
                      href="https://www.sendinblue.com/legal/termsofuse/"
                    >
                      terms of use
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

Component.propTypes = {}

export default Component
